import React, { useState, useEffect, useContext, useCallback } from "react"
import queryString from "query-string"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import Layout from "components/common/layout.js"
import api from "apis"
import { navigate } from "gatsby-link"
import { getDateYMD, textEdit, getThumb } from "utils"
import DetailHeader from "components/post/detailHeader"
import * as styles from "./detail.module.css"
import smallShare from "images/smallShare.png"
import peachIcons from "images/peachIcons.png"
import { smalloffFavor, smallonFavor } from "images"
import { onLike, onShare } from "utils"
import { table } from "utils/data.js"
import SlidePagination from "components/tools/slide/slidePagination.js"
import modalContext from "context/modalContext"
import { getVideoListAsync } from "store/modules/postReducer.js"
import dompurify from "dompurify"
import dayjs from "dayjs"
import NewTag from "components/tools/newTag"

import Like from "components/tools/Like"

const Detail = props => {
  const { video_no, currentPage, pagePostSize, idx } = queryString.parse(
    props.location.search
  )
  const { setmodalOpen, setModalMain } = useContext(modalContext)
  const currentPagePostSize = 6
  const [post, setPost] = useState({})
  const { videoList, videoTotal } = useSelector(
    ({ postReducer: { video } }) => video,
    shallowEqual
  )
  const dispatch = useDispatch()
  const getVideoList = useCallback(
    (currentPage, pagePostSize, order) =>
      dispatch(getVideoListAsync(currentPage, pagePostSize, order)),
    [dispatch]
  )

  const [newTag, setNewTag] = useState(false)

  useEffect(() => {
    const margin = videoList.length < 4 ? "144px" : "300px"
    document.getElementById("right").style.marginTop = margin
    document.getElementById("left").style.marginTop = margin
  }, [videoList])

  const [slidePage, setSlidePage] = useState(
    idx
      ? Math.ceil(
          (pagePostSize * (currentPage - 1) + Number(idx)) / currentPagePostSize
        )
      : Number(currentPage)
  )
  console.log(slidePage)

  useEffect(() => {
    ;(async () => {
      const post = await api.getVideoDetail(video_no)
      if (post)
        setPost({
          thumb: getThumb(post?.thumb_title_uuid),
          img: getThumb(post?.thumb_inner_uuid),
          title: post?.title,
          contents: post?.bodyText,
          intro: textEdit(post?.introText),
          time: getDateYMD(post?.registerTime, "YYYY.MM.DD"),
          Like: post?.Like,
          hit: post?.hit,
          like: post?.like,
          label: post?.label,
        })
    })()
  }, [video_no, currentPage, pagePostSize, idx])

  return (
    <Layout>
      <DetailHeader
        left={
          <div>
            <img
              src={post?.img}
              style={{
                minWidth: "350px",
                maxWidth: "350px",
                height: "350px",
              }}
            />
          </div>
        }
        right={
          <div className={styles.rightBox}>
            <div className="tagBox">
              <div className={styles.tagBox}>
                <div className={styles.labelTag}>{post?.label}</div>
                <NewTag registerTime={post?.time} />
              </div>
            </div>
            <div className={styles.title}>{post?.title}</div>
            <div className={styles.intro}>{post?.intro}</div>
            <div>
              <div>
                <img src={peachIcons} />
                <div style={{ color: "#6f6f6f" }}>피치서가</div>
                <div>{post?.time}</div>
              </div>
            </div>
            <div>
              <div style={{ display: "flex" }}>
                <span>view</span>
                <div style={{ fontWeight: "normal", marginLeft: "6.4px" }}>
                  {post?.hit}
                </div>
              </div>
              {/* <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={post.Like ? smallonFavor : smalloffFavor}
                  onClick={e => {
                    onLike(e, video_no, table.get(6), true)
                  }}
                  style={{
                    width: "18px",
                    height: "18px",
                    marginRight: "5px",
                  }}
                />
                <div style={{ fontWeight: "normal" }}>{post?.like}</div>
              </div> */}

              <Like
                on={smallonFavor}
                off={smalloffFavor}
                keyword={"video"}
                isLike={post?.Like}
                style={{
                  img: {
                    width: "20px",
                    height: "20px",
                    marginRight: "5px",
                  },
                  count: {
                    fontWeight: "normal",
                  },
                }}
                postNumber={video_no}
                count={post?.like}
              />
              <img
                src={smallShare}
                onClick={() => {
                  onShare(setmodalOpen, setModalMain)
                }}
                style={{
                  width: "20px",
                  height: "20px",
                }}
              />
            </div>
          </div>
        }
      >
        {typeof window !== "undefined" ? (
          <div
            style={{
              textAlign: "left",
              minHeight: "100px",
              minWidth: "780px",
              maxWidth: "780px",
            }}
            dangerouslySetInnerHTML={{
              __html: post?.contents,
            }}
          />
        ) : null}
      </DetailHeader>
      <div className={styles.pgCon}>
        <SlidePagination
          total={videoTotal}
          currentPage={slidePage}
          setCurrentPage={setSlidePage}
          postSize={currentPagePostSize}
          getlist={getVideoList}
          viewAll={() => {
            navigate("/visible/media/list")
          }}
          title={"더 많은 영상 스토리"}
          line={false}
        >
          <div className={styles.main}>
            {videoList.map(post => (
              <div
                key={post.video_no}
                onClick={() => {
                  navigate(`/visible/media/detail?video_no=${post.video_no}`)
                }}
                style={{
                  fontWeight: Number(video_no) === post.video_no ? "700" : "",
                  cursor: "pointer",
                }}
              >
                <img src={getThumb(post.thumb_title_uuid)} />

                <div
                  style={{
                    display: "flex",
                    gap: "17px",
                    alignItems: "center",
                    marginTop: "-10px",
                  }}
                >
                  <img
                    src={peachIcons}
                    style={{
                      width: "40px",
                      height: "40px",
                      marginTop: "5px",
                    }}
                  />
                  <div>
                    <div>{post.title}</div>
                    <div>{post.introText}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </SlidePagination>
      </div>
    </Layout>
  )
}

export default Detail
